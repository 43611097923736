var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('div', {
    staticClass: "event-list-container"
  }, _vm._l(_vm.boards, function (board) {
    return _c('div', {
      key: board._id,
      staticClass: "event-list",
      class: {
        'event-list--closed': board.category == '종료된 이벤트'
      }
    }, [_c('a', {
      staticClass: "box",
      attrs: {
        "href": `${_vm.$route.path}/${board._id}?mode=view`
      }
    }, [_c('div', {
      staticClass: "thumbnail"
    }, [_c('span', {
      staticClass: "image"
    }, [_c('span', {
      style: {
        backgroundImage: `url('${board.thumb}')`
      }
    })]), _c('span', {
      staticClass: "img"
    })]), _c('div', {
      staticClass: "context"
    }, [_c('span', {
      staticClass: "title"
    }, [_vm._v(_vm._s(board.subject))]), _vm._v(" " + _vm._s(board.summary) + " ")])]), _c('div', {
      staticClass: "info"
    }, [_c('span', {
      staticClass: "state"
    }, [_vm._v(_vm._s(board.category))]), _c('span', {
      staticClass: "date"
    }, [_vm._v("이벤트 기간 : " + _vm._s(board.period))])])]);
  }), 0), _vm.boards.length < _vm.$props.summary.totalCount ? _c('div', {
    staticClass: "bottom-button"
  }, [_c('button', {
    staticClass: "button button--orange button--border",
    on: {
      "click": function ($event) {
        return _vm.$emit('loadmore');
      }
    }
  }, [_vm._v("더보기")])]) : _vm._e()]);

}
var staticRenderFns = []

export { render, staticRenderFns }